import { Navigate, RouteObject } from 'react-router';
import { lazy, Suspense } from 'react';
import SuspenseLoader from '../components/SuspenseLoader';
import SidebarLayout from 'src/layouts/SidebarLayout';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Dashboard = Loader(
  lazy(() => import('src/pages/UserManagement/Dashboard'))
);
const Logger = Loader(lazy(() => import('src/pages/Logger')));

const Vendor = Loader(lazy(() => import('src/pages/AssetManagement/Vendor')));
const AssetType = Loader(
  lazy(() => import('src/pages/AssetManagement/Assettypes'))
);
const ServiceHistory = Loader(
  lazy(() => import('src/pages/AssetManagement/AssetServiceHistory'))
);
const AssetView = Loader(
  lazy(() => import('src/pages/AssetManagement/AssetView'))
);
const AssetReturn = Loader(
  lazy(() => import('src/pages/AssetManagement/AssetReturn'))
);
const InventoryManagement = Loader(
  lazy(() => import('src/pages/AssetManagement/InventoryManagement'))
);
const AssetAllocation = Loader(
  lazy(() => import('src/pages/AssetManagement/AssetAllocation'))
);
const FixPayScale = Loader(
  lazy(() => import('src/pages/SalaryManagement/PayScaleFix'))
);
const LeaveMatrix = Loader(
  lazy(() => import('src/pages/LeaveManagement/LeaveMatrix'))
);
const LeaveHistory = Loader(
  lazy(() => import('src/pages/LeaveManagement/LeaveHistory'))
);
const LeaveRequests = Loader(
  lazy(() => import('src/pages/LeaveManagement/LeaveRequest'))
);
const Onboarding = Loader(
  lazy(() => import('src/pages/EmployeeManagement/Onboarding'))
);
const RMBuddyAllocation = Loader(
  lazy(() => import('src/pages/EmployeeManagement/RMBuddy'))
);
const Exit = Loader(
  lazy(() => import('src/pages/EmployeeManagement/EmployeeExit'))
);
const PayScaleView = Loader(
  lazy(() => import('src/pages/SalaryManagement/PayScaleView'))
);
const ConversionView = Loader(
  lazy(() => import('src/pages/SalaryManagement/Conversion/ConversionView'))
);
const Appraisal = Loader(
  lazy(() => import('src/pages/SalaryManagement/Appraisal'))
);
const Employees = Loader(
  lazy(() => import('src/pages/EmployeeManagement/Employees'))
);
const ResignedEmployees = Loader(
  lazy(() => import('src/pages/EmployeeManagement/ResignedEmployees'))
);
const HolidayList = Loader(
  lazy(() => import('src/pages/LeaveManagement/Holiday'))
);
const AssetRequests = Loader(
  lazy(() => import('src/pages/RequestManagement/AssetRequest'))
);
const PfRequests = Loader(
  lazy(() => import('src/pages/RequestManagement/PfRequest/PfRequest'))
);
const ProfileRequest = Loader(
  lazy(
    () => import('src/pages/RequestManagement/ProfileRequest/ProfileRequest')
  )
);
const User = Loader(lazy(() => import('src/pages/UserManagement/User')));
const Roles = Loader(lazy(() => import('src/pages/UserManagement/Roles')));
const PredictSalaryIndex = Loader(
  lazy(() => import('src/pages/SalaryManagement/SalaryPrediction'))
);
const ConfirmationView = Loader(
  lazy(() => import('src/pages/SalaryManagement/Confirmation/ConfirmationView'))
);
const MonthEndProcess = Loader(
  lazy(() => import('src/pages/ClosureManagement/MonthEndProcess'))
);
const YearEndProcess = Loader(
  lazy(() => import('src/pages/ClosureManagement/YearEndProcess'))
);
const LoggedInResetPassword = Loader(
  lazy(() => import('src/pages/UserManagement/LoggedInResetPassword/index'))
);
const AdminSiteRoutes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />
      }
    ]
  },
  {
    element: <SidebarLayout />,
    children: [
      {
        path: 'employee',
        children: [
          {
            path: 'employees',
            element: <Employees />
          },
          {
            path: 'onboarding',
            element: <Onboarding />
          },
          {
            path: 'rmbuddy',
            element: <RMBuddyAllocation />
          },
          {
            path: 'employee-exit',
            element: <Exit />
          },
          {
            path: 'resigned',
            element: <ResignedEmployees displayType="Resigned" />
          }
        ]
      },
      {
        path: 'leave',
        children: [
          {
            path: 'leavehistory',
            element: <LeaveHistory />
          },
          {
            path: 'leave-account',
            element: <LeaveHistory />
          },
          {
            path: 'leave-matrix',
            element: <LeaveMatrix />
          },
          {
            path: 'holiday',
            element: <HolidayList />
          }
        ]
      },
      {
        path: 'salary',
        children: [
          {
            path: 'payscale',
            element: <PayScaleView />
          },
          {
            path: 'fixpayscale',
            element: <FixPayScale />
          },
          {
            path: 'confirmation-view',
            element: <ConfirmationView />
          },
          {
            path: 'conversion-view',
            element: <ConversionView />
          },
          {
            path: 'appraisal',
            element: <Appraisal />
          },
          {
            path: 'predictsalary',
            element: <PredictSalaryIndex />
          }
        ]
      },
      {
        path: 'asset',
        children: [
          {
            path: 'vendors',
            element: <Vendor />
          },
          {
            path: 'servicehistory',
            element: <ServiceHistory />
          },
          {
            path: 'assetview',
            element: <AssetView />
          },
          {
            path: 'assettypes',
            element: <AssetType />
          },
          {
            path: 'managestock',
            element: <InventoryManagement />
          },
          {
            path: 'assetreturn',
            element: <AssetReturn />
          },
          {
            path: 'assetallocation',
            element: <AssetAllocation />
          }
        ]
      },
      {
        path: 'request-management',
        children: [
          {
            path: 'asset-requests',
            element: <AssetRequests />
          },
          {
            path: 'pf-requests',
            element: <PfRequests />
          },
          {
            path: 'leave-requests',
            element: <LeaveRequests />
          },
          {
            path: 'profile-requests',
            element: <ProfileRequest />
          }
        ]
      },
      {
        path: 'user',
        children: [
          {
            path: 'users',
            element: <User />
          },
          {
            path: 'roles',
            element: <Roles />
          },
          {
            path: 'logs',
            element: <Logger />
          }
        ]
      },
      {
        path: 'closure',
        children: [
          {
            path: 'month-end',
            element: <MonthEndProcess />
          },
          {
            path: 'year-end',
            element: <YearEndProcess />
          }
        ]
      },
      {
        path: 'logged-in-reset-password',
        element: <LoggedInResetPassword />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
];
export default AdminSiteRoutes;
