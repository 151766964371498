import DashboardIcon from '@mui/icons-material/Dashboard';
import BadgeIcon from '@mui/icons-material/Badge';
import StorageIcon from '@mui/icons-material/Storage';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DevicesIcon from '@mui/icons-material/Devices';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import ListIcon from '@mui/icons-material/List';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PortraitIcon from '@mui/icons-material/Portrait';
import DvrIcon from '@mui/icons-material/Dvr';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { SvgIconTypeMap } from '@mui/material';

export interface MenuItem {
  link?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  badge?: string;
  items?: MenuItem[];
  name: string;
  key?: string;
}

export interface MenuItems {
  items: MenuItem[];
}

const menuItems: MenuItems[] = [
  {
    items: [
      {
        name: 'Dashboard',
        link: '/',
        icon: DashboardIcon
      },
      {
        name: 'Employee Management',
        link: '/',
        icon: PeopleAltIcon,
        items: [
          {
            name: 'Profile',
            link: '/employee/employees',
            icon: PeopleOutlineIcon,
            key: 'Employee - Find All'
          },
          {
            name: 'Onboarding',
            link: '/employee/onboarding',
            icon: BadgeIcon,
            key: 'Onboarding - Find All'
          },
          {
            name: 'RM/Buddy Allocation',
            link: 'employee/rmbuddy',
            icon: AdminPanelSettingsIcon,
            key: 'RmBuddy - History - FindAll'
          },
          {
            name: 'Exit/Resignation',
            link: 'employee/employee-exit',
            icon: ExitToAppIcon,
            key: 'Employee Exit - Find All'
          },
          {
            name: 'Resigned Employees',
            link: 'employee/resigned',
            icon: ExitToAppIcon,
            key: 'Resigned Employees'
          }
        ]
      },
      {
        name: 'Request Management',
        link: '/request',
        icon: MarkunreadIcon,
        items: [
          {
            name: 'Leave Requests',
            link: 'request-management/leave-requests',
            icon: PlaylistAddIcon,
            key: 'Leave Request - FindAll'
          },
          {
            name: 'Asset Requests',
            link: 'request-management/asset-requests',
            icon: PlaylistAddIcon,
            key: 'Asset Request - FindAll'
          },
          {
            name: 'Profile Update Requests',
            link: 'request-management/profile-requests',
            icon: SummarizeIcon,
            key: 'Profile Edit Request - FindAll'
          },
          {
            name: 'PF Update Requests',
            link: 'request-management/pf-requests',
            icon: ContentPasteGoIcon,
            key: 'PF Update Request - FindAll'
          }
        ]
      },
      {
        name: 'Leave Management',
        link: '/leave',
        icon: AccessTimeIcon,
        items: [
          {
            name: 'Leave Account',
            link: 'leave/leave-account',
            icon: ListIcon,
            key: 'Leave Balance - FindAll'
          },

          {
            name: 'Leave Matrix',
            link: 'leave/leave-matrix',
            icon: SummarizeIcon,
            key: 'LeaveMatrix - FindAll'
          },
          {
            name: 'Holiday List',
            link: 'leave/holiday',
            icon: CalendarMonthIcon,
            key: 'Holiday Year List - FindAll'
          }
        ]
      },
      {
        name: 'Salary Management',
        link: '',
        icon: CurrencyRupeeIcon,
        items: [
          {
            name: 'Current Payscale',
            link: '/salary/payscale',
            icon: CreditCardIcon,
            key: 'PayScale - List'
          },
          {
            name: 'Appraisal',
            link: '/salary/appraisal',
            icon: ConfirmationNumberIcon,
            key: 'Appraisal - Find All'
          },
          {
            name: 'Confirmation',
            link: '/salary/confirmation-view',
            icon: ConfirmationNumberIcon,
            key: 'Confirmation - Find All'
          },
          {
            name: 'Conversion',
            link: '/salary/conversion-view',
            icon: ConfirmationNumberIcon,
            key: 'Conversion - Find All'
          },
          {
            name: 'Predict Salary',
            link: '/salary/predictsalary',
            icon: CurrencyExchangeIcon,
            key: 'Predict Salary - View'
          }
        ]
      },
      {
        name: 'Asset Management',
        link: '',
        icon: DevicesIcon,
        items: [
          {
            name: 'Asset View',
            link: '/asset/assetview',
            icon: ListAltIcon,
            key: 'Asset Type - Find All'
          },
          {
            name: 'Asset Allocation',
            link: '/asset/assetallocation',
            icon: PlaylistAddCheckIcon,
            key: 'Asset Allocation - FindAll'
          },
          {
            name: 'Manage Stock',
            link: '/asset/managestock',
            icon: DvrIcon,
            key: 'Asset Type - FindAll'
          },
          {
            name: 'Asset Types',
            link: '/asset/assettypes',
            icon: FormatListNumberedIcon,
            key: 'Asset Type - FindAll'
          },
          {
            name: 'Vendors',
            link: '/asset/vendors',
            icon: PortraitIcon,
            key: 'Vendor - List'
          },
          {
            name: 'Asset Return',
            link: '/asset/assetreturn',
            icon: ExitToAppIcon,
            key: 'Asset - Update'
          },
          {
            name: 'Service History',
            link: '/asset/servicehistory',
            icon: BuildCircleIcon
          }
        ]
      },
      {
        name: 'User Management',
        link: '/user',
        icon: PermContactCalendarIcon,
        items: [
          {
            name: 'Users',
            link: 'user/users',
            icon: BadgeIcon,
            key: 'User - Find All'
          },
          {
            name: 'Roles',
            link: 'user/roles',
            icon: StorageIcon,
            key: 'Role - Find All'
          },
          {
            name: 'Logs',
            link: 'user/logs',
            icon: ManageHistoryIcon,
            key: 'Logger - findAll'
          }
        ]
      },
      {
        name: 'Closure Managment',
        link: '/closure',
        icon: BackupTableIcon,
        items: [
          {
            name: 'Month End Process',
            link: 'closure/month-end',
            icon: CalendarTodayIcon,
            key: 'Closure - Month End'
          },
          {
            name: 'Year End Process',
            link: 'closure/year-end',
            icon: CalendarMonthIcon,
            key: 'Role - Find All'
          }
        ]
      }
    ]
  }
];

export default menuItems;
